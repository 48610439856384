<template>
  <div>
    <slider />
    <section style="background-color: var(--white);color: var(--darkBackground);" class="boosted-odds-section">
      <br>
      <div class="boosted-odds-wrapper pl-3 pr-3">
        <div style="background-color: var(--white);" class="boosted-odds-card mr-1">
          <div class="boosted-odds-game-row row">
            <div class="col-12 text-left">
              <div style="float: right;font-weight: 600;" class="boosted-a-1">
                Expire: {{ fixture.date }}
              </div>
            </div>
          </div>
          <div class="row">
            <div class="text-dark col-6 text-center mb-2 league-text">
              {{ fixture.tournament }}
            </div>
            <div class="text-dark col-6 text-center mb-2 league-text">
              <span class="boosted-a-1 ">#
                {{ fixture.game_id }}
              </span>
            </div>
          </div>
          <FreebetOdd :outcomes="fixture.outcomes" :fixture="fixture" :match_id="fixture.match_id" />
        </div>
      </div>
      <hr style="background-color: var(--light-gray);" />

      <section class="logon-sec px-2">
        <div v-show="action_login && !isLoggedIn" class="mb-3">

          <div class="text-center">
            <span class="small-text text-dark">Phone Number</span>
          </div>
          <div class="mb-2">
            <div class="input-group"
              style="border: 1px black solid;border-radius: 5px;background-color: white;color: black;width: 100%;height: 40px;text-align: center;">
              <input type="text" class="form-control" id="inputPass"
                style="border-radius: 5px;background-color: white;color: black;text-align: center;" name="mobile"
                placeholder="e.g. 0712345678" aria-label="e.g. 0712345678" v-model="msisdn">
            </div>
          </div>

          <div class="text-center">
            <span class="small-text text-dark">Password</span>
          </div>
          <div class="mb-2">
            <div class="input-group"
              style="border: 1px black solid;border-radius: 5px;background-color: white;color: black;width: 100%;height: 40px;text-align: center;">
              <input :type="type" class="form-control" id="inputPass"
                style="border-radius: 5px;background-color: white;color: black;text-align: center;" name="password"
                placeholder="Enter Password" aria-label="Enter Password" v-model="password">
              <div class="input-group-prepend"
                style="border-radius: 5px;background-color: white;color: black;text-align: center;">
                <span style="background-color: var(--yellow);" class="btn btn-sm" type="button"><img
                    @click="showPassword" style="width: 20px;" :src="btnText"></span>
              </div>
            </div>
          </div>

          <div class="text-center mb-3">
            <a class="join-button py-2 form-control" @click="login">
              Place Freebet
            </a>
          </div>
        </div>
        <div v-show="action_reedem" class="text-center mb-3">
          <a class="join-button py-2 form-control" @click="handlePlaceBetClick">
            Place Freebet
          </a>
        </div>

      </section>
    </section>

    <div style="background-color: var(--white);color: var(--darkBackground);" class="p-3 card-prof">

      <div v-if="action_verify_password" class="boosted-a-1 mt-2">
        EASY! <br>
        Just select your odd above then<br>
        register below to claim your FREE BET
      </div>

      <div v-if="action_verify_password" class="boosted-a-1 mb-3">
        Enter your phone number and Password below
      </div>

      <div v-if="action_verify_password" class="boosted-a-1 ">
        Verify Account! <br>Enter Verification code send to your phone
      </div>

      <div v-show="action_verify_password" class="-3">

        <div class="mb-0">
          <input type="number" class="form-control " name="password" min="0" pattern="[0-9]*"
            style="border-radius: 5px;background-color: white;color: black;text-align: center;" placeholder="e.g. 1234"
            aria-label="Verification Code" v-model="code">
        </div>

        <div class="mb-2 mt-2">
          <small class="text-dark">Enter the verification code sent to your phone</small>
        </div>

        <div class="w-100 mb-3">
          <button v-bind:class="loading" @click="verifyAccount" class="join-button w-100 py-2 form-control">Place
            Freebet</button>
        </div>

      </div>
    </div>

    <BottomNavigation active-bets="0" page="home"></BottomNavigation>
  </div>
</template>

<style scoped>
.input-signup,
.input-login {
  border: 1px solid #ccc;
  border-radius: .5em;
  height: 3em;
}
</style>

<script>
// import identity from "@/services/identity";
// import freebet from "@/services/freebet";
import bettingserve from "@/services/bettingserve";
import { pushToDataLayer } from '@/utils/gtm';
import axios from "@/services/identity";

import FreebetOdd from "@/components/FreebetOdd.vue";

const slider = () => import("./Slider.vue");
const BottomNavigation = () => import("./BottomNavigation.vue");

export default {
  name: "Freebet",
  components: {
    FreebetOdd,
    slider,
    BottomNavigation
  },
  data: function () {
    return {
      game_id: 0,
      fixture: {},
      initial_fixture: {},
      market_group: {
        id: 0,
      },
      totals: [],
      live: false,
      seconds: 0,
      possible_win: "",
      payout: "",
      tax: "",
      odd_id: "",
      // outcome: {},
      msisdn: "",
      password: "",
      loading: "",
      code: "",
      action_verify_password: false,
      profile: {},
      action_success: false,
      message: "",
      id: '',
      type: 'password',
      type2: 'password',
      type3: 'password',
      btnText: '/img/icons/eye.svg',
      btnText2: '/img/other/eye.svg',
      btnText3: '/img/other/eye.svg'
      /*market_id: 0,
      outcome_id: 0,
      specifier: "",*/
    };
  },
  mounted() {
    this.$store.dispatch("setCurrentPage", "freebet");
    this.getMatch();
    this.reloadProfile();
    // this.getBalance();
  },
  computed: {
    // balance: function () {
    //   return this.$store.state.freeBetBalance;
    // },
    isNewProfile: function () {
      var prof = this.getProfile();
      var s = prof.new_profile;
      return s;
    },
    slip: function () {
      return this.$store.state.freebetslip;
    },
    successBagroundImg: function () {
      return this.getURL("/img/icons/bet_placed.svg");
    },
    action_login: function () {
      var p = this.getAuth();
      return !p && !this.action_verify_password;
    },
    action_reedem: function () {
      var p = this.getProfile();
      return p && !this.action_success;
    },
    stake: function () {
      return 30;
    },
    isLoggedIn: function () {

      var m = this.getProfile();
      var p = m.a;
      return p;
    },
    marketGroups: function () {
      return this.$store.state.market_groups;
    },
    visibleMarkets: function () {
      return this.fixture.market;
    },
    collapsibleMarkets: function () {
      var max = 9;
      var x = 0;
      var markets = [];

      this.jQuery.each(this.fixture.market, function (k, v) {
        x++;

        if (x > max) {
          markets.push(v);
        }
      });

      return [];
    },

    home_team: function () {
      return this.getHomeCompetitorName(this.fixture.name);
    },

    away_team: function () {
      return this.getAwayCompetitorName(this.fixture.name);
    },

    event_time: function () {
      var minutes = parseInt(this.seconds / 60);
      //var seconds = this.seconds % 60;
      var min = minutes;
      if (minutes < 10) {
        min = "0" + min;
      }
      //console.log('secs '+sec);
      return min + "'";
      //return min+":"+sec;
    },
  },
  methods: {
    // getBalance() {
    //   var p = this.getProfile();
    //   this.buttonClicked = true;
    //   if (!p) {
    //     return;
    //   }

    //   var path = process.env.VUE_APP_BASE_FREEBET_URL + "/balance";

    //   axios
    //     .get(path, {
    //       headers: {
    //       "api-key": this.getAuth(),
    //       },
    //     })
    //     .then((res) => {
    //       // console.log("Response Bonus", res)
    //       var bonus = res.data;

    //       // console.log("Freebet Balance" + bonus)
    //       var vm = this;
    //       vm.EventBus.$emit("init:mqtt");
    //       this.$store.dispatch("setFreeBetBalance", bonus);

    //       this.setBonus(bonus);
    //     })
    //     .catch(() => {
    //       this.loading = "";
    //     });
    // },
    handlePlaceBetClick(event) {
      this.placeBet(event);
    },
    trackPlaceBetSuccessfulButtonClick(event) {
      pushToDataLayer('gtm.betPlacedSuccessful', {
        category: 'Button',
        action: 'Click',
        label: 'betPlacedSuccessful',
        element: event.target
      });
    },
    trackPlaceBetUnsuccessfulButtonClick(event) {
      pushToDataLayer('gtm.BetPlaceUnsuccessful', {
        category: 'Button',
        action: 'Click',
        label: 'BetPlaceUnsuccessful',
        element: event.target
      });
    },
    placeBet: function (event) {
      var p = this.getProfile();
      if (!p) {
        this.$toast.open({
          message: 'Please login to proceed',
          type: 'error',
          position: 'top'
        });
        this.action_login = true;
        return;
      }

      // var bets = [];
      var bSlip = this.getObject("fslip");

      if (!bSlip || bSlip.length === 0) {
        this.setError("Failed", "Bet slip is empty or invalid");
        return;
      }

      const bets = [
        {
          odd_id: bSlip[0].odd_id,
          producer_id: 3,
          match_id: bSlip[0].match_id
        }
      ]
      var data = {
        bet_type: 1,
        bets: bets,
        booking_code: "",
        campaign: this.getValue("campaign") || "default_campaign",
        ip_address: "", // consider setting a default or a valid IP
        medium: this.getValue("medium") || "default_medium",
        source: this.isMobile() ? 2 : 1,
        stake: 50,
        utm_source: this.getValue("utm_source") || "default_utm_source",
        referrer: document.referrer,
      };

      this.loading = "loading";
      var vm = this;
      var path = process.env.VUE_APP_BASE_FREEBET_URL + "/bet";

      bettingserve
        .post(path, data, {
          headers: {
            "api-key": vm.getAuth(),
          },
        })
        .then((res) => {
          this.loading = "";
          console.log("Success placeBet:", res);

          // var responseData = res.data;
          // if (responseData && responseData.status === 0) {
          var message = res.data.data;
          vm.message = message;
          vm.setSuccess("Success", message);
          vm.trackPlaceBetSuccessfulButtonClick(event);
          setTimeout(() => {
            window.location.href = "/";
          }, 2000);
          // } else {
          //   vm.setError("Failed", "Unexpected response format");
          // }
        })
        .catch((err) => {
          this.loading = "";
          console.log("Error message", err);
          vm.trackPlaceBetUnsuccessfulButtonClick(event);

          if (err.response) {
            const { status, data } = err.response;
            let message = data?.error_message || "Unknown error";

            switch (status) {
              case 401:
                this.$toast.open({
                  message: 'Your session on this device has expired',
                  type: 'error',
                  position: 'top'
                });
                this.$toast.open({
                  message: 'Your session on this device has expired',
                  type: 'error',
                  position: 'top'
                });
                vm.logout();
                break;
              case 400:
                // vm.setError("Invalid request", message);
                this.$toast.open({
                  message: message,
                  type: 'error',
                  position: 'top'
                });
                break;
              case 417:
                // vm.setError("Bet details violate settings", message);
                this.$toast.open({
                  message: message,
                  type: 'error',
                  position: 'top'
                });
                break;
              case 422:
                // vm.setError("Invalid odd or match status", message);
                this.$toast.open({
                  message: message,
                  type: 'error',
                  position: 'top'
                });
                break;
              case 423:
                // vm.setError("Profile is blocked", message);
                this.$toast.open({
                  message: message,
                  type: 'error',
                  position: 'top'
                });
                break;
              case 500:
                // vm.setError("Internal server error", message);
                this.$toast.open({
                  message: message,
                  type: 'error',
                  position: 'top'
                });
                break;
              case 502:
                // vm.setError("Service unavailable", message);
                this.$toast.open({
                  message: message,
                  type: 'error',
                  position: 'top'
                });
                break;
              default:
                this.$toast.open({
                  message: message,
                  type: 'error',
                  position: 'top'
                });
                this.$toast.open({
                  message: message,
                  type: 'error',
                  position: 'top'
                });
                break;
            }
          } else if (err.request) {
            // vm.setError("Failed", "Network error. Please check your connection.");
            this.$toast.open({
              message: 'Network error. Please check your connection',
              type: 'error',
              position: 'top'
            });
            console.log("Request error:", err.request);
          } else {
            // vm.setError("Failed", err.message || "An unexpected error occurred");
            this.$toast.open({
              message: err.message,
              type: 'error',
              position: 'top'
            });
            console.log("Error:", err.message);
          }
        });
    },
    login: function () {
      this.reset();
      this.removeAuth();

      this.msisdn = this.msisdn.replace(/\D/g, '');

      if (this.msisdn.length < 9 || parseInt(this.msisdn) === 0) {

        this.$toast.open({
          message: 'Please enter a valid mobile number',
          type: 'error',
          position: 'top'
        });
        return;

      }

      if (this.password.length < 4) {

        this.$toast.open({
          message: 'Please enter a password of more than 4 characters',
          type: 'error',
          position: 'top'
        });
        return;

      }

      this.loading = 'loading';
      this.spinner = true;

      var vm = this;

      var path = "/login";

      var currentDate = new Date();
      var login_tag = parseInt(currentDate.getTime() + '' + Math.floor(Math.random() * (9 * (Math.pow(10, 2)))) + (Math.pow(10, 2)));
      this.setValue("login_tag", login_tag);

      var utm_source = this.getValue("utm_source");
      var utm_medium = this.getValue("utm_medium");
      var utm_campaign = this.getValue("utm_campaign");

      console.log('here we are')

      axios.post(path, JSON.stringify({
        msisdn: parseInt(this.msisdn),
        password: this.password,
        login_tag: login_tag,
        source: utm_source,
        medium: utm_medium,
        campaign: utm_campaign,
        referrer: document.referrer
      }))
        .then((res) => {
          vm.loading = '';
          vm.spinner = false;
          var profile = res.data;
          var status = res.status;
          console.log("status " + status);
          if (status === 201 || status === 204) {
            vm.action_verify_password = true;
            vm.setVerifyAccount();
            return;
          } else {
            vm.setProfile(profile);
            var auth = profile.auth;
            vm.setAuth(auth);
            console.log("profile " + profile.t);
            this.$toast.open({
              message: 'Login successful!',
              type: 'success',
              position: 'top'
            });
            vm.EventBus.$emit('init:mqtt');

            vm.placeBet();
          }
        })
        .catch((err) => {
          vm.loading = "";
          if (err.response) {
            this.$toast.open({
              message: err.response.data.error_message,
              type: 'error',
              position: 'top'
            });
            this.$toast.open({
              message: err.response.data.error_message,
              type: 'error',
              position: 'top'
            });
            if (parseInt(err.response.status) === 428) {
              vm.action_verify_password = true;
            }
          } else if (err.request) {
            this.$toast.open({
              message: 'Check your network connection and try again',
              type: 'error',
              position: 'top'

            });
            console.log(JSON.stringify(err.request));
          } else {
            this.$toast.open({
              message: 'Check your network connection and try again',
              type: 'error',
              position: 'top'

            });
            console.log(JSON.stringify(err));
          }
        });
    },
    verify: function () {
      this.reset();

      if (this.code.length < 4) {
        this.$toast.open({
          message: 'Please enter a valid verification code',
          type: 'error',
          position: 'top'
        });
        return;
      }

      this.spinner = true;
      this.loading = 'loading';

      var vm = this;
      var path = process.env.VUE_APP_URL_VERIFY;
      var currentDate = new Date();
      var login_tag = parseInt(currentDate.getTime() + '' + Math.floor(Math.random() * (9 * (Math.pow(10, 2)))) + (Math.pow(10, 2)));
      this.setValue("login_tag", login_tag);

      var utm_source = this.getValue("utm_source") || '';
      var utm_medium = this.getValue("utm_medium") || '';
      var utm_campaign = this.getValue("utm_campaign") || '';

      var payload = {
        msisdn: parseInt(this.msisdn),
        code: parseInt(this.code),
        login_tag: login_tag,
        source: utm_source,
        medium: utm_medium,
        campaign: utm_campaign,
        referrer: document.referrer
      };

      console.log("Request Payload:", payload);

      axios.post(path, payload, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then(res => {
          vm.spinner = false;
          vm.loading = '';
          var profile = res.data;
          var status = res.status;

          console.log("Response Status:", status);
          console.log("Response Data:", profile);

          vm.setProfile(profile);
          var auth = profile.auth;
          vm.setAuth(auth);

          vm.setSuccess("Congratulations!!", "Your account has been verified successfully");
          vm.EventBus.$emit('init:mqtt');

          if (parseInt(vm.placeBet) === 1) {
            vm.setValue("placeBet", 0);
            vm.EventBus.$emit('event:betslip:show');
          } else {
            window.location.href = "/";
          }
        })
        .catch(err => {
          vm.spinner = false;
          vm.loading = '';
          console.log("Error:", err);

          if (err.response) {
            console.log("Error Response Data:", err.response.data);
            console.log("Error Response Status:", err.response.status);
            console.log("Error Response Headers:", err.response.headers);

            var errorMessage = err.response.data || "An error occurred";
            // vm.setError("Failed", errorMessage);
            this.$toast.open({
              message: errorMessage,
              type: 'error',
              position: 'top'
            });
          } else if (err.request) {
            this.$toast.open({
              message: 'Check your network connection and try again',
              type: 'error',
              position: 'top'
            });
            console.log("Error Request:", err.request);
          } else {
            this.$toast.open({
              message: 'Check your network connection and try again',
              type: 'error',
              position: 'top'
            });
            console.log("Error Message:", err.message);
          }
        });
    },
    verifyAccount: function () {
      this.reset();

      if (this.code.length < 4) {
        this.$toast.open({
          message: 'Please enter a valid verification code',
          type: 'error',
          position: 'top'
        });
        return;
      }

      this.spinner = true;
      this.loading = 'loading';

      var vm = this;
      var path = process.env.VUE_APP_URL_VERIFY;
      var currentDate = new Date();
      var login_tag = parseInt(currentDate.getTime() + '' + Math.floor(Math.random() * (9 * (Math.pow(10, 2)))) + (Math.pow(10, 2)));
      this.setValue("login_tag", login_tag);

      var utm_source = this.getValue("utm_source") || '';
      var utm_medium = this.getValue("utm_medium") || '';
      var utm_campaign = this.getValue("utm_campaign") || '';

      var payload = {
        msisdn: parseInt(this.msisdn),
        code: parseInt(this.code),
        login_tag: login_tag,
        source: utm_source,
        medium: utm_medium,
        campaign: utm_campaign,
        referrer: document.referrer
      };

      console.log("Request Payload:", payload);

      axios.post(path, payload, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then(res => {
          vm.spinner = false;
          vm.loading = '';
          var profile = res.data;
          var status = res.status;

          console.log("Response Status:", status);
          console.log("Response Data:", profile);

          vm.setProfile(profile);
          var auth = profile.auth;
          vm.setAuth(auth);

          vm.setSuccess("Congratulations!!", "Your account has been verified successfully");
          vm.EventBus.$emit('init:mqtt');

          if (parseInt(vm.placeBet) === 1) {
            vm.setValue("placeBet", 0);
            vm.EventBus.$emit('event:betslip:show');
          } else {
            window.location.href = "/";
          }
        })
        .catch(err => {
          vm.spinner = false;
          vm.loading = '';
          console.log("Error:", err);

          if (err.response) {
            console.log("Error Response Data:", err.response.data);
            console.log("Error Response Status:", err.response.status);
            console.log("Error Response Headers:", err.response.headers);

            var errorMessage = err.response.data || "An error occurred";
            this.$toast.open({
              message: errorMessage,
              type: 'error',
              position: 'top'
            });
          } else if (err.request) {
            this.$toast.open({
              message: 'Check your network connection and try again',
              type: 'error',
              position: 'top'
            });
            console.log("Error Request:", err.request);
          } else {
            this.$toast.open({
              message: 'Check your network connection and try again',
              type: 'error',
              position: 'top'
            });
            console.log("Error Message:", err.message);
          }
        });
    },
    pickOutcome: function (outcome) {
      this.outcome = outcome;
      this.odd_id = outcome.odd_id;
      var odd = outcome.odds;
      this.possible_win = this.stake * odd;
      this.tax = 0;
      this.payout = this.possible_win - this.stake;
    },
    getHomeCompetitorName: function (matchName) {
      if (matchName === undefined) {
        return "";
      }
      return matchName.split("vs.")[0];
    },

    getAwayCompetitorName: function (matchName) {
      if (matchName === undefined) {
        return "";
      }
      return matchName.split("vs.")[1];
    },

    // getMatch: function () {
    //   var vm = this;
    //   var path = process.env.VUE_APP_BASE_FREEBET_URL + '/freebet/fixture';

    //   axios
    //       .get(path)
    //       .then((res) => {
    //         if (Array.isArray(res.data)) {
    //           vm.fixture = res.data[0];
    //         } else {
    //           vm.fixture = res.data;
    //         }
    //       })
    //       .catch(() => {
    //       });
    // },
    async getMatch() {
      try {
        const response = await axios.get('https://freebet.tucheze.com/freebet/fixture');
        console.log(response.data); // Check the response here
        this.fixture = response.data.data[0];
        console.log(this.fixture); // Check the fixture data
      } catch (error) {
        console.error('Error fetching match data:', error);
      }
    },
    getOddDirectionClass: function (outcome) {
      if (parseInt(outcome.active) === 0 || parseInt(outcome.status) !== 0) {
        return "";
      }

      if (parseFloat(outcome.odds) >= parseFloat(outcome.previous_odds)) {
        return "arrow green up";
      }
      return "arrow red down";
    },
    showPassword: function () {
      if (document.getElementById('inputPass').type == "password") {
        document.getElementById('inputPass').type = "text"
        document.getElementById('inputPass').style.color = "white"
      } else {
        document.getElementById('inputPass').type = "password"
        document.getElementById('inputPass').style.color = "rgba(255,255,255,.75)"
      }
      if (this.type === 'password') {
        this.type = 'text'
        this.btnText = '/img/icons/eye-slash.svg'
      } else {
        this.type = 'password'
        this.btnText = '/img/icons/eye.svg'
      }
    },
  },
  filters: {
    amount: function (val) {
      if (val === undefined) {
        return val;
      }
      return parseFloat(val).toFixed(2).toLocaleString();
    },
  },
};
</script>